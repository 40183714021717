
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ClientClaimDetails from "@/components/individual/ClientClaimDetails.vue";

export default defineComponent({
  name: "broker-client-motor-insurance-claims-details",
  components: { ClientClaimDetails },
  props: ["publicId"],
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Details", ["Claims", "Motor Insurance Claim"]);
    });
  }
});
